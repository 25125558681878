import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ArrowRightBlue,
  EditSvgBlue,
  PhotoSvg,
  PrintBarcodeSvg,
} from "../../icons";
import { Link } from "react-router-dom";
// import Checkbox from "../../common/Checkbox";
// import CheckAll from "../../common/CheckAll";
import Spinner from "../../common/Spinner";
import Pagination from "../../common/Pagination";
// import TableOutsideSearch from "../../common/TableOutsideSearch";
import TableSearch from "../../common/TableSearch";
import ParcelPrint from "../../modals/groups/ParcelPrint";

const ParcelsTable = ({
  currentPage,
  onNextPage,
  perPage,
  onPrevPage,
  handlePerPage,
  handlePageClick,
  handleShowPicturesList,
  handleShowEditForm,
  showEditForm,
  editParcelId,
  parcelsFormData,
  setParcelsFormData,
}) => {
  const [subCategories, setSubCategories] = useState([]);
  const [printData, setPrintData] = useState({});
  const { isLoading, categoriesWithMaster } = useSelector(
    (state) => state.groups
  );
  const parcels = useSelector((state) => state.groups?.parcels?.parcels);
  const recordsNumber = useSelector(
    (state) => state.groups?.parcels?.recordsNumber
  );
  const { t } = useTranslation();
  const printRef = useRef();

  useEffect(() => {
    if (categoriesWithMaster.length > 0) {
      const allSubcategories = categoriesWithMaster.flatMap(
        (category) => category.children || []
      );
      setSubCategories(allSubcategories);
    }
  }, [categoriesWithMaster]);

  const getSubCategoryDescription = (categoryId) => {
    const subCategory = subCategories.find((sub) => sub.id === categoryId);
    return subCategory ? subCategory.description : t("text.not_found");
  };

  const columns = [
    { header: "Name", key: "fullName" },
    { header: "Tracking Number", key: "tdsCode" },
    { header: "Room Number", key: "roomNumber" },
    { header: "Category", key: "warehouseCategoryId" },
    { header: "Total weight", key: "weight" },
    { header: "CBM", key: "cbm" },
    { header: "E.A.I.₾, ", key: "expectedAmountGel" },
  ];

  const handlePrint = async (id) => {
    const parcel = await parcels?.find((item) => item.id === id);

    if (parcel) {
      await setPrintData(parcel);
    }
    const printContent = printRef.current.innerHTML;
    const originalContent = document.body.innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
    window.location.reload();
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col gap-20">
          <ParcelPrint printRef={printRef} data={printData} />
          <div>
            {/* <div className="flex justify-end items-center p-4">
              <div className="max-w-xxl flex justify-end items-center gap-4">
                <TableOutsideSearch
                  formData={parcelsFormData}
                  setFormData={setParcelsFormData}
                  searchKey="roomNumber"
                  placeholderValue={t("text.room_number")}
                />
                <TableOutsideSearch
                  formData={parcelsFormData}
                  setFormData={setParcelsFormData}
                  searchKey="tdsCode"
                  placeholderValue={t("text.TDS_code")}
                />
              </div>
            </div> */}
            <table className="w-full bg-white border-collapse">
              <thead className="bg-gray-100 text-sm text-blue-4">
                <tr>
                  <th className="w-12 p-4 text-left border-b border-r border-black/5 font-medium">
                    <div className="w-full flex justify-center">Barcode</div>
                  </th>
                  {columns?.map((column) => (
                    <th
                      key={column.key}
                      className={`${
                        column.key === "roomNumber" || column.key === "tdsCode"
                          ? "min-w-8"
                          : ""
                      } max-w-5 text-left border-b font-medium`}
                    >
                      <div className="relative p-4 w-full truncate border-r border-black/5">
                        {(column.key === "roomNumber" ||
                          column.key === "tdsCode") && (
                          <>
                            <span className="block truncate pr-3">
                              {column.header}
                            </span>
                            <TableSearch
                              formData={parcelsFormData}
                              setFormData={setParcelsFormData}
                              searchKey={column.key}
                            />
                          </>
                        )}
                        {column.key !== "roomNumber" &&
                          column.key !== "tdsCode" && (
                            <span>{column.header}</span>
                          )}
                      </div>
                    </th>
                  ))}
                  <th className="max-w-2 text-left border-b font-medium w-20">
                    <div className="p-4 w-full truncate">
                      {t("text.actions")}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {parcels?.map((row, index) => (
                  <tr
                    key={index}
                    className={`hover:bg-gray-50 border-black/5 border-b ${
                      showEditForm && editParcelId === row.id
                        ? "bg-blue-50"
                        : ""
                    }`}
                  >
                    <td className="py-2 px-3 border-r border-black/5 flex justify-center">
                      <span
                        className="cursor-pointer"
                        onClick={() => handlePrint(row.id)}
                      >
                        <PrintBarcodeSvg />
                      </span>
                    </td>
                    {columns?.map((column, colIndex) => (
                      <td
                        key={colIndex}
                        className="max-w-5 text-xs text-blue-4 font-medium"
                      >
                        <div className="py-2 px-3 w-full truncate border-r border-black/5">
                          {column.key === "warehouseCategoryId" ? (
                            <Tooltip
                              title={getSubCategoryDescription(row[column.key])}
                            >
                              <span>
                                {getSubCategoryDescription(row[column.key])}
                              </span>
                            </Tooltip>
                          ) : (
                            <Tooltip title={row[column.key]}>
                              <span>{row[column.key]}</span>
                            </Tooltip>
                          )}
                        </div>
                      </td>
                    ))}
                    <td className="py-2 px-3 border-r border-black/5 w-20 flex justify-center">
                      <div className="flex items-center justify-center">
                        <Tooltip title="Parcel Details">
                          <Link
                            to={`/ParcelGroup/parcel/${row.id}`}
                            className="w-6 h-6 min-w-6 flex justify-center items-center"
                          >
                            <ArrowRightBlue />
                          </Link>
                        </Tooltip>

                        <Tooltip title="Photos">
                          <button
                            className={`w-6 h-6 min-w-6 flex justify-center items-center relative ${
                              row?.parcelFiles &&
                              row?.parcelFiles?.length > 0 &&
                              "after:block after:h-2 after:w-2 after:rounded-full after:bg-blue-2 after:absolute after:-top-0.5 after:-right-0.5"
                            }`}
                            onClick={() => handleShowPicturesList(row.id)}
                          >
                            <PhotoSvg />
                          </button>
                        </Tooltip>

                        <Tooltip title="Edit">
                          <button
                            onClick={() => handleShowEditForm(row.id)}
                            className="w-6 h-6 min-w-6 flex justify-center items-center"
                          >
                            <EditSvgBlue />
                          </button>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {recordsNumber > 1 && (
            <Pagination
              currentPage={currentPage}
              onNextPage={onNextPage}
              onPrevPage={onPrevPage}
              handlePerPage={handlePerPage}
              perPage={perPage}
              recordsNumber={recordsNumber}
              handlePageClick={handlePageClick}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ParcelsTable;
