import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerGroup, getGroups } from "../../../features/groups/GroupsSlice";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../libs/AppContext";
import ButtonDefault from "../../buttons/ButtonDefault";
import Input from "../../common/Input";
import Select from "../../common/Select";
import ModalsHeadline from "../components/ModalsHeadline";

const GroupsModal = ({
  closeModal,
  setShowModal,
  setShowResponseMessage,
  setResponseMessage,
}) => {
  const [formData, setFormData] = useState({
    barcode: "",
    warehouseCountryId: "",
    flightId: "",
    departmentId: "",
  });
  const [errors, setErrors] = useState({
    barcode: "",
    warehouseCountryId: "",
    departmentId: "",
  });
  const [countryName, setCountryName] = useState("");
  const resetSelectedOptionRef1 = useRef(null);
  const resetSelectedOptionRef2 = useRef(null);
  const flights = useSelector((state) => state.groups?.flights);
  const departments = useSelector((state) => state.groups?.departments);
  const countries = useSelector((state) => state.groups?.countries);
  const {
    groupsCountryId,
    groupsFormData,
    setGroupsFormData,
    setNumbersSearchValue,
    setFlightsSearchValue,
  } = useAppContext();
  const memoizedDepartments = useMemo(() => departments || [], [departments]);
  const memoizedFlights = useMemo(() => flights || [], [flights]);
  const departmentsOptions = Array.isArray(memoizedDepartments)
    ? memoizedDepartments.map((item) => item.address)
    : [];

  const flightsOptions = Array.isArray(memoizedFlights)
    ? memoizedFlights.map((item) => item.flightNumber)
    : [];
  const { barcode, warehouseCountryId, flightId, departmentId } = formData;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      warehouseCountryId: groupsCountryId,
    }));
  }, [groupsCountryId]);

  useEffect(() => {
    const foundCountry = countries?.find(
      (item) => item.countryId === groupsCountryId
    );
    if (foundCountry) {
      setCountryName(foundCountry.countryDictionaryKey);
    }
  }, [countries, groupsCountryId]);

  const updateDepartmentId = (departmentId) => {
    setFormData({
      ...formData,
      departmentId: departmentId,
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      departmentId: "",
    }));
  };

  const updateFlightsId = (flightId) => {
    setFormData({
      ...formData,
      flightId: flightId,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddGroup = async (e) => {
    e.preventDefault();

    if (!barcode) {
      setErrors({
        barcode: barcode ? "" : "Barcode is required",
      });
      return;
    }

    const groupData = { barcode, warehouseCountryId, flightId, departmentId };
    try {
      const response = await dispatch(registerGroup(groupData));
      if (response.payload.type === "success") {
        dispatch(getGroups(groupsFormData));
        setShowModal(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
        setFormData({
          barcode: "",
          warehouseCountryId: groupsCountryId,
          flightId: "",
          departmentId: "",
        });
        setGroupsFormData((prev) => ({
          ...prev,
          flightNumber: "",
          barcode: "",
        }));
        setNumbersSearchValue("");
        setFlightsSearchValue("");
        if (resetSelectedOptionRef1.current) {
          resetSelectedOptionRef1.current();
        }
        if (resetSelectedOptionRef2.current) {
          resetSelectedOptionRef2.current();
        }
      }

      if (response.error.message === "Rejected") {
        setShowModal(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors({
          barcode: error.response.data.barcode || "",
          departmentId: error.response.data.barcode || "",
        });
      }
    }
  };

  return (
    <form
      onSubmit={handleAddGroup}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline text="Add parcel group" onClick={closeModal} />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="text-sm font-medium text-blue-900">
            {t(countryName)}
          </div>
          <div className="relative w-full">
            <Input
              text="List No"
              name="barcode"
              value={formData.barcode}
              onchange={handleInputChange}
              error={errors.barcode}
              errormark={errors.barcode ? "error-border" : ""}
            />
            {errors.barcode && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.barcode}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Select
              text="Flight No"
              options={flightsOptions}
              value={formData.flightId}
              type="flights"
              flightsData={memoizedFlights}
              updateFlightsId={updateFlightsId}
              resetSelectedOption={resetSelectedOptionRef1}
              error={errors.flightId}
              errormark={errors.flightId ? "error-border" : ""}
            />
            {errors.flightId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.flightId}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Select
              text="Select branch"
              options={departmentsOptions}
              value={formData.departmentId}
              type="groupsDepartments"
              departmentsData={memoizedDepartments}
              updateDepartmentId={updateDepartmentId}
              resetSelectedOption={resetSelectedOptionRef2}
              error={errors.departmentId}
              errormark={errors.departmentId ? "error-border" : ""}
            />
            {errors.departmentId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.departmentId}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="p-6">
        <ButtonDefault type="submit">Add parcel group</ButtonDefault>
      </div>
    </form>
  );
};

export default GroupsModal;
