import axios from "axios";

const getCounties = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/parcel_group/get_working_departments`,
    config
  );

  return response.data;
};

const getDepartments = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/parcel_group/get_departments`,
    config
  );

  return response.data;
};

const getFlights = async (token, warehouseCountryId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    warehouseCountryId: warehouseCountryId,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/parcel_group/get_flights`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const getGroups = async (
  token,
  warehouseCountryId,
  flightNumber,
  status,
  barcode,
  page,
  perPage
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    warehouseCountryId: warehouseCountryId,
    flightNumber: flightNumber,
    status: status,
    barcode: barcode,
    page: page,
    perPage: perPage,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/parcel_group/get_parcel_groups`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const registerGroup = async (groupData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel_group/parcel_group_registration`,
    groupData,
    config
  );

  return response.data;
};

const updateGroup = async (updatedGroupData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel_group/parcel_group_change`,
    updatedGroupData,
    config
  );

  return response.data;
};

const deleteGroup = async (parcelGroupIds, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel_group/parcel_group_delete`,
    parcelGroupIds,
    config
  );

  return response.data;
};

const getStatuses = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/parcel_group/get_parcel_group_statuses`,
    config
  );

  return response.data;
};

const changeRate = async (rateData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URI}/parcel_group/change_ccy_rate`,
      rateData,
      config
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const getBarcode = async (token, barcode) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    barcode: barcode,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/parcel_group/get_barcode`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const exportParcels = async (parcelGroupId, token, language = "EN") => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URI}/parcel_group/export_parcels?language=${language}`,
      { parcelGroupId },
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const exportUsaParcels = async (parcelGroupId, token, language = "EN") => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URI}/parcel_group/export_usa_parcels?language=${language}`,
      { parcelGroupId },
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const sendEmail = async (emailData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URI}/parcel_group/send_email`,
      emailData,
      config
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const sendSms = async (smsData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URI}/parcel_group/send_sms`,
      smsData,
      config
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const changeGroupStatus = async (parcelGroupIds, status, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const requestData = {
    parcelGroupIds: parcelGroupIds,
    status: status,
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel_group/change_status`,
    requestData,
    config
  );

  return response.data;
};

const getParcels = async (
  token,
  parcelGroupId,
  tdsCode,
  roomNumber,
  page,
  perPage
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    parcelGroupId: parcelGroupId,
    tdsCode: tdsCode,
    roomNumber: roomNumber,
    page: page,
    perPage: perPage,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/parcel_group/get_parcels_new`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const getNewParcelInfo = async (token, tds_code) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    tds_code: tds_code,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/parcel_group/get_new_parcel_info`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const genetareTrackingCode = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URI}/parcel/generate_tracking_code`,
      {},
      config
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const searchUser = async (token, roomNumber) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URI}/parcel_group/search_user`,
      { roomNumber },
      config
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const getCategoriesWithMaster = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URI}/parcel/get_categories_with_master`,
      config
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const receiveParcelNew = async (token, parcelData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URI}/parcel_group/receive_parcel_new`,
      parcelData,
      config
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateParcelNew = async (token, parcelData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URI}/parcel_group/change_parcel_new`,
      parcelData,
      config
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const getParcelServices = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URI}/parcel_group/get_parcel_services`,
      config
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const groupsService = {
  getCounties,
  getDepartments,
  getGroups,
  registerGroup,
  updateGroup,
  deleteGroup,
  getStatuses,
  getFlights,
  changeRate,
  getBarcode,
  exportParcels,
  exportUsaParcels,
  sendEmail,
  sendSms,
  changeGroupStatus,
  getParcels,
  getNewParcelInfo,
  genetareTrackingCode,
  searchUser,
  getCategoriesWithMaster,
  receiveParcelNew,
  updateParcelNew,
  getParcelServices,
};

export default groupsService;
