const ButtonPrimary = (props) => {
  const { onClick, children } = props;
  return (
    <button
      onClick={onClick}
      className="px-4 py-4 w-full text-xs font-medium text-white border border-blue-2 rounded bg-blue-2 text-center cursor-pointer transition-all duration-300 hover:bg-blue-700"
    >
      {children}
    </button>
  );
};

export default ButtonPrimary;
